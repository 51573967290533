<script setup lang="ts">
const { locale } = useI18n();
const emit = defineEmits(["close"]);
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const localPath = useLocalePath();
const { $client } = useNuxtApp();
// const currentBot = useState("currentBot");

// const { data } = await $client.bot.detail.useQuery({ id: props.botId });
const botName = ref("");
const createAssistant = async () => {
  if (botName.value === "") return;

  const bot = await $client.bot.add.mutate({
    name: botName.value,
    language: locale.value,
  });
  // console.log(bot);
  navigateTo(localPath("/bots/" + bot.id + "/bots/edit"));
  emit("close");
};

watch(
  () => props.open,
  (newVal) => {
    if (newVal) {
      botName.value = "";
      refreshPermissions();
    }
  }
);

const { data: permission } = await $client.user.getPermissionScope.useQuery({
  scope: {
    botAdd: "bot.add",
  },
});
const refreshPermissions = async () => {
  permission.value = await $client.user.getPermissionScope.query({
    scope: {
      botAdd: "bot.add",
    },
  });
};
</script>

<template>
  <ModalInnerWrapper
    :open="props.open"
    class="m-0 flex w-[900px] max-w-[600px] flex-col divide-y p-0"
    hide-close-button
    offcenter
    @close="emit('close')"
  >
    <div class="inline-flex h-20 w-full items-center justify-between">
      <div class="mx-5">
        <h1 class="text-2xl font-normal">{{ t("pages.bots.addTitle") }}</h1>
      </div>
      <CustomButton
        class="mx-5"
        type="primary"
        :disabled="!permission.botAdd"
        @click="createAssistant()"
      >
        {{ t("pages.bots.add") }}
      </CustomButton>
    </div>
    <div class="inline-flex w-full items-center">
      <div class="mx-5 my-8 mb-16 w-full">
        <InputTextField
          v-model="botName"
          test-id="createBotName"
          :label="t('pages.edit.inputs.name')"
        />
      </div>
    </div>
  </ModalInnerWrapper>
</template>
